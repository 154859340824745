<template>
  <div class="gg-container">
    <div class="search-container">
      <!-- <div class="search-container-fn-input">
					<label>权限名称/路由：</label>
					<el-input
									size="small"
									placeholder="请输入科室名称"
									prefix-icon="el-icon-search"
									v-model="searchParams.name"
									clearable
					>
					</el-input>
			</div> -->

      <!-- <div class="search-container-fn-input">
					 <label>权限类型：</label>
					 <el-select v-model="searchParams.type" placeholder="请选择"  clearable>
							 <el-option
									 class="depart-class"
									 v-for="item in typeOptions"
									 :key="item.value"
									 :label="item.label"
									 :value="item.value">
							 </el-option>
					 </el-select>
			 </div> -->

      <!-- <div class="search-container-fn-input">
					<label>创建时间：</label>
					<el-date-picker
							v-model="searchParams.created_at"
							size="small"
							type="datetimerange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							clearable
							format="yyyy-MM-dd"
							value-format="yyyy-MM-dd"
							align="left">
					</el-date-picker>
			</div> -->

      <div class="search-container-fn-input" style="margin-left: auto">
        <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleEditTableHead"
            >编辑表头
          </el-button>
        </div>

        <div class="search-container-fn-input">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-refresh"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="handleResetSearch"
            >重置查找
          </el-button>
        </div>

        <div class="search-container-fn-input">
          <el-button
            type="primary"
            size="mini"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="editItem('add')"
          >
            <i class="el-icon-plus" style="font-size: 13px" />
            新增</el-button
          >
        </div>

        <div class="search-container-fn-input">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-edit-outline"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
            @click="editItem('edit')"
            >编辑
          </el-button>
        </div>

        <div class="search-container-fn-input">
          <el-button
            type="danger"
            size="mini"
            style="margin-right: 10px; padding: 6px 7px;margin-left: 5px"
            @click="handlerDelete"
          >
            <i class="el-icon-delete" style="font-size: 13px" />
            删除</el-button
          >
        </div>

        <!--<el-button type="primary" size="mini" @click="getList('rest')">查询</el-button>-->
        <!--<el-button type="success" size="mini" @click="editItem('add')">新增</el-button>-->
      </div>
    </div>

    <!--<el-table-->
    <!--	v-loading="listLoading"-->
    <!--	:data="tableData"-->
    <!--	row-key="id"-->
    <!--	border-->
    <!--	style="width: 100%">-->
    <!--	-->
    <!--	<el-table-column-->
    <!--		align="center"-->
    <!--		width="170"-->
    <!--		prop="name"-->
    <!--		label="角色名称">-->
    <!--		<template slot-scope="{row}">-->
    <!--			<span style="margin-left:20px">{{row.name}}</span>-->
    <!--			&lt;!&ndash; <i v-if="row.level<=2" class="el-icon-circle-plus icon-depart" @click="editItem('add',row)"></i> &ndash;&gt;-->
    <!--			&lt;!&ndash; <i class="el-icon-caret-right icon-depart"></i> &ndash;&gt;-->
    <!--		</template>-->
    <!--	</el-table-column>-->
    <!--	-->
    <!--	<el-table-column-->
    <!--		v-if="is_admin==1"-->
    <!--		prop="router"-->
    <!--		width="220px"-->
    <!--		align="center"-->
    <!--		label="机构名称">-->
    <!--		<template slot-scope="{row}">-->
    <!--			{{row.agent_name ? row.agent_name : (is_admin == 1 ? '平台' : '')}}-->
    <!--		</template>-->
    <!--	</el-table-column>-->
    <!--	-->
    <!--	<el-table-column-->
    <!--		align="center"-->
    <!--		label="角色权限">-->
    <!--		<template slot-scope="{row}">-->
    <!--			<el-tag type="success" style="margin-right:10px;margin-bottom:5px;"-->
    <!--							v-for="(item,index) in row.permissions_name" :key="index">{{item}}-->
    <!--			</el-tag>-->
    <!--			&lt;!&ndash; <el-button type="text" size="small" @click="editItem('detail',row)">查看详情</el-button> &ndash;&gt;-->
    <!--		</template>-->
    <!--	</el-table-column>-->
    <!--	<el-table-column-->
    <!--		prop="created_at"-->
    <!--		label="创建时间"-->
    <!--		width="260"-->
    <!--		align="center">-->
    <!--	</el-table-column>-->
    <!--	-->
    <!--	&lt;!&ndash;            <el-table-column&ndash;&gt;-->
    <!--	&lt;!&ndash;                    prop="quick_reply"&ndash;&gt;-->
    <!--	&lt;!&ndash;                    label="排序">&ndash;&gt;-->
    <!--	&lt;!&ndash;                <template slot-scope="{row}">&ndash;&gt;-->
    <!--	&lt;!&ndash;                    <span v-if="row.id==12"><i class="dot"></i>已禁用</span>&ndash;&gt;-->
    <!--	&lt;!&ndash;                    <span v-if="row.id==11" class="text-success"><i class="dot"></i>可用</span>&ndash;&gt;-->
    <!--	&lt;!&ndash;                </template>&ndash;&gt;-->
    <!--	&lt;!&ndash;            </el-table-column>&ndash;&gt;-->
    <!--	-->
    <!--	-->
    <!--	<el-table-column-->
    <!--		prop="address"-->
    <!--		label="操作"-->
    <!--		width="200"-->
    <!--		align="center">-->
    <!--		<template slot-scope="{row}">-->
    <!--			<el-button style="padding: 7px 8px;" @click="editItem('edit',row)" type="primary" size="mini"-->
    <!--								 icon="el-icon-edit-outline">修改-->
    <!--			</el-button>-->
    <!--			<el-button style="padding: 7px 8px;" @click="delItem(row)" type="danger" size="mini"-->
    <!--								 icon="el-icon-edit-outline">删除-->
    <!--			</el-button>-->
    <!--			&lt;!&ndash; <el-button style="padding: 7px 8px;margin-left: 5px;" v-if="row.status==1" type="danger" :disabled="statusDis" size="mini" @click="handleStatus(row,2)"-->
    <!--			class="iconfont icon-jinyong">禁用</el-button> &ndash;&gt;-->
    <!--			&lt;!&ndash; <el-button style="padding: 7px 8px;margin-left: 5px;" v-else-if="row.status==2" type="success" :disabled="statusDis" size="mini" @click="handleStatus(row,1)" icon="el-icon-check">启用</el-button> &ndash;&gt;-->
    <!--		</template>-->
    <!--	</el-table-column>-->

    <!--</el-table>-->

    <!----------------表格---------------->
    <el-table
      border
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="625"
      style="width: 100%;z-index:0"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="index" align="center" width="50">
      </el-table-column>
      <el-table-column type="selection" width="55" align="center" />
      <template v-for="(column, index) in tableHead">
        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-if="column.field_type === 'textBtn' && column.visible === true"
          show-overflow-tooltip
        >
          <!--<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="hover">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
									<i style="margin-left: 5px" class="search-icon el-icon-search"></i>
								</div>
								<el-input
										size="mini"
										placeholder=""
										prefix-icon="el-icon-search"
										v-model="searchParams.buy_count"
										clearable
										@change="getList('restPage')"
									>
									</el-input>
							</el-popover>
						</template>-->
          <template slot-scope="scope">
            <a
              style="font-size: 12px; color: #2379fb"
              @click.prevent="handleCustomerDetail(scope.row)"
            >
              {{ scope.row[column.column_prop] }}
            </a>
          </template>
        </el-table-column>

        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.field_type === 'select' && column.visible === true"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <el-switch
              v-if="'status' == column.column_prop"
              class="switch"
              @change="changeExamine(scope.row)"
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>

            <el-switch
              v-if="'show_mobile_status' == column.column_prop"
              class="switch"
              @change="changeExamineShowMobileStatus(scope.row)"
              v-model="scope.row.show_mobile_status"
              :active-value="1"
              :inactive-value="0"
              active-text="启用"
              inactive-text="禁用"
              active-color="#13ce66"
              inactive-color="#C8C8C8"
            >
            </el-switch>
          </template>
        </el-table-column>

        <el-table-column
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="
            column.field_type === 'imageBtn' && column.visible === true
          "
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row[column.column_prop].length > 0"
              style="font-size: 12px; color: #2379fb"
              @click="
                handleShowImage(
                  scope.row[column.column_prop],
                  scope.row[column.column_prop][0]
                )
              "
            >
              <a>点击查看图片</a>
              <el-image
                style="width: 0px; height: 0px"
                :ref="scope.row[column.column_prop][0]"
                :src="imageUrl"
                :preview-src-list="scope.row[column.column_prop]"
                :z-index="9999"
              >
              </el-image>
            </div>
            <div v-else>
              暂无
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :sortable="tableHeadSortable.includes(column.column_prop)"
          :prop="column.column_prop"
          :label="
            column.column_label_user_definition
              ? column.column_label_user_definition
              : column.column_label
          "
          :width="column.width ? column.width : ''"
          :key="index"
          align="center"
          v-else-if="column.visible === true"
          show-overflow-tooltip
        >
          <template #header>
            <el-popover
              placement="bottom"
              title=""
              min-width="160"
              trigger="click"
              v-if="
                searchTableHead.filter(
                  (item) => item.name == column.column_prop
                ).length > 0
              "
            >
              <span slot="reference" class="search-header">
                <span class="search-title">{{
                  column.column_label_user_definition
                    ? column.column_label_user_definition
                    : column.column_label
                }}</span>
                <i
                  style="margin-left: 3px;font-weight: bolder; font-size: 20px"
                  class="el-icon-search"
                  :class="{
                    isSearch: searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                    'el-icon-zoom-in': searchTableHead.filter(
                      (item) => item.name == column.column_prop
                    )[0].isSearch,
                  }"
                />
              </span>
              <el-input
                v-if="'order_count' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.buy_count"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.buy_count)
                "
              >
              </el-input>

              <el-input
                v-if="'mobile' == column.column_prop"
                size="mini"
                placeholder=""
                prefix-icon="el-icon-search"
                v-model="searchParams.mobile"
                clearable
                @change="handleSearch(column.column_prop, searchParams.mobile)"
              >
              </el-input>

              <el-select
                v-else-if="'agent_name' == column.column_prop"
                style="width:200px"
                v-model="searchParams.agent_id"
                placeholder="请选择"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.agent_id)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in merchantList"
                  :key="item.value"
                  :label="item.merchant_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <el-select
                v-else-if="'visit_level' == column.column_prop"
                style="width:200px"
                v-model="searchParams.visit_level"
                placeholder="请选择"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.visit_level)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in optionsVisitLevel"
                  :key="item.short_name"
                  :label="item.short_name"
                  :value="item.short_name"
                >
                </el-option>
              </el-select>

              <el-select
                v-else-if="'from' == column.column_prop"
                style="width:200px"
                v-model="searchParams.from_id"
                placeholder="请输入客户来源"
                size="small"
                clearable
                @change="handleSearch(column.column_prop, searchParams.from_id)"
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in customerFromOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>

              <el-date-picker
                v-else-if="'created_at' == column.column_prop"
                v-model="choiceDateCreateRecord"
                :default-time="['00:00:00', '23:59:59']"
                type="daterange"
                size="mini"
                value-format="yyyy-MM-dd HH-mm-ss "
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                @change="handleCreateRecordChangeDate()"
              >
              </el-date-picker>

              <el-select
                v-else-if="'staff_name' == column.column_prop"
                style="width:200px"
                v-model="searchParams.staff_name"
                placeholder="请选择"
                size="mini"
                clearable
                @change="
                  handleSearch(column.column_prop, searchParams.staff_name)
                "
              >
                <template slot="prefix">
                  <i
                    class="el-icon-search"
                    style="width: 25px; line-height: 32px;"
                  ></i>
                </template>
                <el-option
                  v-for="item in staffOptions"
                  :key="item.id"
                  :label="item.staff_name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-popover>
            <span v-else>{{
              column.column_label_user_definition
                ? column.column_label_user_definition
                : column.column_label
            }}</span>
          </template>
          <template slot-scope="scope">
            <span v-if="column.column_prop === 'buy_status'">{{
              scope.row[column.column_prop] === 1 ? "未购" : "已购"
            }}</span>
            <span v-else-if="column.column_prop === 'use_status'">
              {{ scope.row[column.column_prop] | useStatusFormat }}
            </span>
            <span v-else-if="column.column_prop === 'is_wx'">
              {{ scope.row[column.column_prop] == "0" ? "否" : "是" }}
            </span>
            <span v-else-if="column.column_prop === 'supervise_status'">
              {{ scope.row[column.column_prop] | formatSuperviseStatus }}
            </span>
            <span v-else>{{ scope.row[column.column_prop] }}</span>
          </template>
        </el-table-column>
      </template>

      <!--<el-table-column label="操作" align="center">-->
      <!--	<template slot-scope="scope">-->
      <!--		<el-button size="mini" type="primary" icon="el-icon-edit"-->
      <!--							 style="padding: 6px 7px; margin-right: 10px;-->
      <!--                      background-color: #2362FB; border-color: #2362FB"-->
      <!--							 @click="handleEdit(scope.row)">编辑-->
      <!--		</el-button>-->
      <!--		<el-button size="mini" type="danger" icon="el-icon-delete"-->
      <!--							 style="padding: 6px 7px; "-->
      <!--							 @click="handleDelete(scope.row)">删除-->
      <!--		</el-button>-->
      <!--		-->
      <!--	</template>-->
      <!--</el-table-column>-->
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <!--新增编辑 快捷回复-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="60%"
      v-if="dialogVisible"
    >
      <el-form
        ref="ruleForm"
        :model="currInfo"
        label-width="80px"
        :rules="rules"
        class="demo-ruleForm"
      >
        <el-form-item label="角色名称" prop="name">
          <template v-if="openType == 'detail'">
            {{ currInfo.name }}
          </template>
          <template v-else>
            <el-input
              v-model="currInfo.name"
              maxlength="12"
              show-word-limit
            ></el-input>
          </template>
        </el-form-item>
        <el-form-item label="类型" prop="type" v-if="is_admin == '1'">
          <template v-if="openType == 'detail'">
            {{ getTypeName(currInfo.type) }}
          </template>
          <template v-else>
            <el-radio-group v-model="currInfo.type" @change="handleAuthType">
              <el-radio-button
                style="margin-right:15px;"
                v-for="item in typeOptions"
                :label="item.value"
                :key="item.value"
                >{{ item.label }}</el-radio-button
              >
            </el-radio-group>
          </template>
        </el-form-item>

        <el-form-item
          label="机构商户"
          prop="agent_id"
          v-if="is_admin == 1 && currInfo.type == 2"
        >
          <el-select v-model="currInfo.agent_id" placeholder="请选择">
            <el-option
              v-for="item in agents"
              :key="item.id"
              :label="item.merchant_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="角色权限" required>
          <el-tree
            @check="handleCheck"
            :default-checked-keys="auth_ids"
            :data="r_permissions"
            show-checkbox
            node-key="id"
            ref="tree"
            highlight-current
            :props="defaultProps"
          >
          </el-tree>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="openType == 'detail'"
          type="success"
          size="small"
          @click="handleEdit"
          >编辑</el-button
        >
        <el-button
          v-else
          type="success"
          size="small"
          @click="saveReply('ruleForm')"
          :disabled="disabled"
          >确定</el-button
        >
      </span>
    </el-dialog>

    <!--编辑表头-->
    <editTableHead
      :isActiveEditTableHead.sync="isActiveEditTableHead"
      @_getAdminFieldIndex="_getAdminFieldIndex"
      v-if="isActiveEditTableHead"
      @getList="getList"
	  :table_type="table_type"
    >
    </editTableHead>
  </div>
</template>

<script>
import { roleStatus, roleAuthList } from "@/api/auth/cdm-permission.js";
import {
  roleList,
  rolePermissionList,
  addRoles,
  updateRoles,
  delRole,
} from "@/api/auth/auth.js";
import { delAccount, getInfo } from "@/api/user";
import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
import roleTableHead from "@/views/accountAuth/tableHead/roleTableHead";//使用表头组件,已弃用,目前使用公共组件
import { mapState } from "vuex";
import globalData from "@/utils/globalData.js";
import editTableHead from "@/components/editTableHead/editTableHead.vue";
import { getAdminFieldIndex } from "@/api/auth/tableAuth";//获取表头数据,已弃用,目前从vuex中获取

export default {
  name: "role",
  components: {
    Pagination,
    editTableHead,
  },
  computed: {
    ...mapState({
      is_admin: (state) => state.user.is_admin,
      agents: (state) => state.auth.agents,
      table_options: (state) => state.user.table_options,
    }),
    r_permissions() {
      if (this.is_admin) {
        if (this.currInfo.type == 1) {
          return this.role_permissions;
        } else if (this.currInfo.type == 2) {
          let permissions = [];
          this.role_permissions.forEach((item) => {
            if (item.type == 1) {
              let child = item.children.filter((v) => v.type == 1);
              permissions.push({
                id: item.id,
                name: item.name,
                type: item.type,
                children: child,
              });
            }
          });
          return permissions;
        } else if (this.currInfo.type == 3) {
          let curPer = this.role_permissions.find((v) => v.name == "审核管理");
          if (curPer) {
            return [
              {
                id: curPer.id,
                name: curPer.name,
                children: curPer.children.filter((v) => v.name == "处方审核"),
              },
            ];
          }
        }else if (this.currInfo.type == 4) {
          let curPer = this.role_permissions.find((v) => v.name == "信息查询");
          if (curPer) {
            return [
              {
                id: curPer.id,
                name: curPer.name,
                children: curPer.children.filter((v) => v.name == "问诊信息"),
              },
            ];
          }
        }
        return [];
      } else {
        let permissions = [];
        this.role_permissions.forEach((item) => {
          if (item.type == 1) {
            let child = item.children.filter((v) => v.type == 1);
            permissions.push({
              id: item.id,
              name: item.name,
              type: item.type,
              children: child,
            });
          }
        });
        return permissions;
      }
    },
    // roles(){
    //     let role = []
    //     if(this.currInfo.type){
    //         role = this.chain_role[this.currInfo.type] ? this.chain_role[this.currInfo.type] : []
    //     }
    //     let roles = [{role_name:'顶级权限',id:0,child:role}]
    //     return roles
    // },
    getTypeName() {
      return (type) => {
        let curType = this.typeOptions.find((v) => v.value == type);
        return curType ? curType.label : "";
      };
    },
    dialogTitle() {
      return (
        {
          add: "添加角色",
          edit: "编辑角色",
          detail: "查看角色",
        }[this.openType] || "添加角色"
      );
    },
  },
  mounted() {
    this.$store.dispatch("auth/getAgents");
  },
  async created() {
    await this._getAdminFieldIndex();
    this.getList();
    this.getRolePermissions();
  },
  data() {
    return {
      table_type: "roleManagement",
      multipleSelection: [],
      searchTableHead: [
        {
          // name: 'recovery_count',
          // isSearch: false
        },
      ],
      tableHeadSortable: ["created_at"],
      tableHead: [],
      choiceDateCreateRecord: [],
      isActiveEditTableHead: false,
      role_permissions: [],
      permissions: [],
      auth_ids: [],
      dialogLoading: false,
      defaultProps: {
        children: "children",
        label: "name",
      },
      authList: [],
      statusDis: false,
      disabled: false,
      // chain_role:[],
      typeOptions: globalData.typeOptions,
      searchParams: {
        depart_name: "",
      },
      currInfo: {
        auth: [],
        role_name: "",
        type: 1,
        sort: "",
      },
      dialogVisible: false,
      replyContent: "",
      openType: "",
      total: 0,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 20,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      tableData: [],
      ruleForm: {
        name: "",
        code: "",
        sort: "",
      },
      rules: {
        // type: [
        //     {required: true, message: '请选择权限类型', trigger: 'change'},
        // ],
        // p_ids: [
        //     {required: true, message: '请选择父级角色', trigger: 'change'},
        // ],
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 2,
            max: 15,
            message: "长度在 2 到 15 个字符",
            trigger: "blur",
          },
        ],
        agent_id: [{ required: true, message: "请选择机构商户" }],
      },
    };
  },

  methods: {
    handleAuthType() {
      console.log(999);
      this.$set(this.currInfo, "agent_id", "");
    },
    async _getAdminFieldIndex() {
      //   try {
      //     this.listLoading = true
      //     var params = {
      //       type: 'roleManagement'
      //     }
      //     const res = await getAdminFieldIndex(params)
      this.tableHead = this.table_options.roleManagement;
      //     console.log(this.tableHead)
      //   } catch (err) {
      //     //在此处理错误
      //   } finally {
      //     this.listLoading = false
      //   }
    },
    handleSelectionChange(val) {
      console.log(val, "val");
      this.multipleSelection = [];
      val.forEach((item) => {
        this.multipleSelection.push(item);
      });
    },
    handleEditTableHead() {
      this.isActiveEditTableHead = true;
    },
    handleResetSearch() {
      this.searchParams = {
        type: "buy",
        is_wx: "",
        visit_level: "",
        buy_count: "",
      };
      this.searchTableHead.forEach((item) => {
        item.isSearch = false;
      });
      this.choiceDateCreateRecord = [];
      this.choiceDateExpire = [];
      this.choiceDateEndVisit = [];
      this.choiceDateEndOrder = [];
      this.choiceDateNextVisit = [];
      this.getList("restPage");
    },

    delItem(row) {
      this.$confirm("确认删除该角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delRole(row.id).then((res) => {
            if (200 == res.code) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getRolePermissions() {
      rolePermissionList(this.currInfo.type).then((res) => {
        if (200 == res.code) {
          this.role_permissions = res.data;
        }
      });
    },
    handleEdit() {
      this.openType = "edit";
    },
    handleCheck(a, b) {
      this.permissions = this.$refs.tree.getCheckedNodes();
      console.log(1111, this.$refs.tree.getCheckedNodes());
      // if(this.openType == 'add'){
      //     this.currInfo.auth = this.$refs.tree.getCheckedKeys()
      // }else{
      //     this.currInfo.auths = this.$refs.tree.getCheckedKeys()
      // }
    },

    handleStatus(row, status) {
      this.statusDis = true;
      roleStatus(row.id, { status }).then((res) => {
        if (res.code == 200) {
          row.status = status;
          var msg = status == 1 ? "启用" : "禁用";
          this.$message.success(`已成功${msg}角色${row.role_name}`);
        }
        this.statusDis = false;
      });
    },

    getList(type) {
      if (type == "rest") {
        this.listQuery.page = 1;
      }
      this.listLoading = true;
      let params = {};
      params.page = this.listQuery.page;
      params.limit = this.listQuery.limit;
      params.name = this.searchParams.name;
      params.type = this.searchParams.type;
      params.created_at = this.searchParams.created_at;
      roleList(params)
        .then((response) => {
          let data = response;
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.listQuery.limit = _data.per_page;
            this.tableData = _data.list;
          }
          this.listLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    editItem(type, item) {
      this.openType = type;
      if (type === "add") {
        this.currInfo = {
          name: "",
          agent_id: "",
          type: 1,
        };
        this.permissions = [];
        this.auth_ids = [];
        this.authList = [];
        console.log(item);
        this.dialogVisible = true;
      } else {
        if (this.multipleSelection.length > 1) {
          this.$message({
            type: "error",
            message: "只能单个编辑!",
          });
          return;
        } else if (this.multipleSelection.length == 0) {
          this.$message({
            type: "error",
            message: "请选择!",
          });
          return;
        }
        console.log(1111111, this.multipleSelection[0]);
        this.auth_ids = this.multipleSelection[0].permissions;

        this.currInfo = {
          name: this.multipleSelection[0].name,
          id: this.multipleSelection[0].id,
          agent_id: this.multipleSelection[0].agent_id,
          type: this.multipleSelection[0].type,
        };
        this.dialogVisible = true;
      }
    },
    handlerDelete() {
      if (this.multipleSelection.length > 1) {
        this.$message({
          type: "error",
          message: "只能单个编辑!",
        });
        return;
      } else if (this.multipleSelection.length == 0) {
        this.$message({
          type: "error",
          message: "请选择!",
        });
        return;
      }
      this.$confirm("此操作将永久删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //确定删除shanc
          delRole(this.multipleSelection[0].id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    _getPermissions() {
      let permissions = [];
      this.permissions.forEach((item) => {
        if (item.type == 3) {
          if (!permissions[item.p_id]) {
            permissions[item.p_id] = [];
          }
          let id = item.id.split("-");
          permissions[item.p_id].push(id[1]);
        } else {
          if (!permissions[item.id]) {
            permissions[item.id] = [];
          }
        }
      });

      let data = [];
      permissions.forEach((item, index) => {
        if (item) {
          data.push({
            id: index,
            operates: item,
          });
        }
      });
      return data;
    },
    async _getInfo() {
      var res = await getInfo();
      let data = res.data;
      let operators = [];
      data.permission.forEach((path) => {
        if (path.children) {
          path.children.forEach((item) => {
            let originOperators = {};
            var name = `/${path.path}/${item.path}`;
            originOperators[name] = `${item.operates}`;
            operators.push(originOperators);
          });
        }
      });
      this.$store.commit("SET_OPERATES", operators);
    },
    _updateRole(id, data) {
      updateRoles(id, data).then(async (res) => {
        if (200 == res.code) {
          this.$message({
            type: "success",
            message: "角色修改成功！",
          });
          this.getList();
          await this._getInfo();
          this.dialogVisible = false;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    _addRoles(data) {
      addRoles(data).then(async (res) => {
        if (200 == res.code) {
          this.$message({
            type: "success",
            message: "角色添加成功！",
          });
          this.getList();
          await this._getInfo();
          this.dialogVisible = false;
        } else {
          this.$message({
            type: "error",
            message: res.msg,
          });
        }
      });
    },
    saveReply(form) {
      let permissions = this._getPermissions();
      console.log(1111, permissions);
      // let keys = this.$refs.tree.getCheckedKeys()
      // console.log('keys',keys)
      // console.log('this.currInfo',this.currInfo)

      this.$refs[form].validate((valid) => {
        if (!valid) {
          return;
        } else {
          if (!this.permissions || this.permissions.length == 0) {
            this.dialogVisible = false;
            // return
            return this.$message.error("请选择角色权限");
          }

          this.disabled = true;
          let params = Object.assign({}, this.currInfo);
          params.permissions = permissions;
          let curAgent = this.agents.find(
            (v) => v.id == this.currInfo.agent_id
          );
          console.log("======curAgent:", curAgent);
          if (curAgent) {
            params.agent_name = curAgent.merchant_name;
          }

          if (this.openType == "add") {
            this._addRoles(params);
          } else {
            this._updateRole(this.currInfo.id, params);
          }
          this.disabled = false;
        }
      });
    },
  },
};
</script>
<style>
.el-radio-group .el-radio-button__inner {
  border: 1px solid #dcdfe6;
}
</style>
<style scoped lang="scss">
.depart-class {
  padding-left: 35px;
}

.icon-depart {
  display: inline-block;
  margin: 0 10px;
  color: #409eff;
}

.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}

.icon-jinyong:before {
  content: "\e650";
  margin-right: 4px;
}

/*!* 控制树形节点横向显示 *!*/
::v-deep
  .el-tree-node.is-expanded
  > .el-tree-node__children
  .el-tree-node__children {
  display: flex;
  flex-wrap: wrap; /* flex横向布局换行显示 */
}

/*!* 去除点击收缩图标时：横向显示的数据会先晃动成纵向显示的再收缩的现象*!*/
/*::v-deep .collapse-transition{*/
/*	transition:none !important;*/
/*}*/
</style>
